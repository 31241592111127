var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],class:[
    'rounded', 
    'p-5',
    'bg-secondary', 
    'text-white', 
    'position-fixed', 
    'top-50', 
    'start-50', 
    'translate-middle' ]},[_c('span',{staticClass:"spinner-border spinner-border-sm me-2",attrs:{"role":"status","aria-hidden":"true"}}),_c('span',[_vm._v(_vm._s(_vm.info))])])}
var staticRenderFns = []

export { render, staticRenderFns }