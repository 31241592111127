<template>
  <div class="container">
    <div class="row gy-4">
      <div class="col-md-6 col-lg-3">
        <h6 class="mb-3 text-dark">关于极客范</h6>
        <div class="lh-lg">
          极客范(Geek Fun)是一套完整的可扩展Web开发案例库，供
          广大学习者使用。通过开发这个项目，可以学习到完整的Web
          开发相关技术环节，掌握完整的Web开发工具栈。
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <h6 class="mb-3 text-dark">导航</h6>
        <ul class="list-unstyled">
          <li>
            <a class="lh-lg link-secondary" href="#">茶与咖啡兼顾的马克杯</a>
          </li>
          <li>
            <a class="lh-lg link-secondary" href="#">朋友欢聚必备的啤酒杯</a>
          </li>
          <li>
            <a class="lh-lg link-secondary" href="#">还能当花瓶的搪瓷杯</a>
          </li>
          <li>
            <a class="lh-lg link-secondary" href="#">杯言杯语话开发</a>
          </li>
          <li><a class="lh-lg link-secondary" href="#">极客范儿</a></li>
        </ul>
      </div>
      <div class="col-md-6 col-lg-3">
        <h6 class="mb-3 text-dark">近期文章</h6>
        <ul class="list-unstyled">
          <li>
            <div class="small">杯言杯语</div>
            <p>
              <a>茶杯与软件开发的紧密联系</a>
            </p>
          </li>
          <li>
            <div class="small">杯言杯语</div>
            <p>
              <a>咖啡因对软件开发的决定性作用</a>
            </p>
          </li>
          <li>
            <div class="small">杯言杯语</div>
            <p><a>杯子与容器的关系</a></p>
          </li>
        </ul>
      </div>
      <div class="col-md-6 col-lg-3">
        <h6 class="mb-3 text-dark">联系方式</h6>
        <ul class="list-unstyled">
          <li>
              <div class="lh-lg"> 地址 : 北京市朝阳区北苑路13号</div>
          </li>
          <li>
              <div class="lh-lg"> 电话 : (800) 888-8888</div>
          </li>
          <li>
            <div class="lh-lg"> 
              邮箱 : 
              <a href="mailto:666@888.cn" class="link-secondary">
                666@888.cn
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
